<script setup lang="ts">
import { BtnGhost, BtnOrange } from '~/components/ui/Buttons/'

type Props = {
  reversed?: boolean
  hasSpacer?: boolean
  disabled?: boolean
  hasCancel?: boolean
  hasNext?: boolean
}
type Emits = {
  next: []
  cancel: []
}

const emit = defineEmits<Emits>()
withDefaults(defineProps<Props>(), {
  reversed: false,
  hasSpacer: true,
  disabled: false,
  hasCancel: true,
  hasNext: true,
})
</script>

<template>
  <div
    class="footer tw-flex tw-w-full tw-items-center tw-justify-end tw-gap-5 tw-bg-white tw-pb-4"
    :class="{ reversed, 'tw-mt-10 tw-p-4': hasSpacer }"
  >
    <BtnGhost v-if="hasCancel" @click="emit(reversed ? 'next' : 'cancel')">
      <slot :name="reversed ? 'next' : 'cancel'">
        <span class="btnText">{{ $t('common.cancel') }}</span>
      </slot>
    </BtnGhost>
    <BtnOrange
      v-if="hasNext"
      :disabled="disabled"
      @click="emit(reversed ? 'cancel' : 'next')"
    >
      <slot :name="reversed ? 'cancel' : 'next'">
        <span class="btnText">{{ $t('common.save') }}</span>
      </slot>
    </BtnOrange>
    <div v-if="$slots.default" class="tw-w-full tw-py-4">
      <slot />
    </div>
  </div>
</template>

<style scoped lang="scss">
div.footer.force_float {
  margin-top: 0px;
  width: 100%;
  padding: 16px;
  margin-bottom: 0px;
  box-shadow: var(--shadow-sticky-bottom);
  @apply tw-justify-between;
  @screen sm {
    @apply tw-justify-end;
  }
}

.largePad768plus div.footer {
  padding: 16px 40px;
}

@media screen and (min-width: 479px) {
  .footer.space_between {
    justify-content: space-between;
    margin-top: unset;
  }
}

@media screen and (max-width: 767px) {
  div.footer {
    width: 100%;
  }

  .largePad768plus div.footer {
    padding: 16px 24px;
  }
}

@media screen and (max-width: 479px) {
  div.footer {
    margin-top: 0px;
    width: 100%;
    position: fixed;
    bottom: 0;
    padding: 16px;
    margin-bottom: 0px;
    justify-content: space-between;
    left: 0px;
    box-shadow: var(--shadow-sticky-bottom);
  }

  .footer > div {
    margin-right: 0px;
  }
}
</style>
